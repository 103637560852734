// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-helios-underwriting-advisers-index-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/advisers/index.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-advisers-index-js" */),
  "component---src-pages-about-helios-underwriting-corporate-governance-index-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/corporate-governance/index.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-corporate-governance-index-js" */),
  "component---src-pages-about-helios-underwriting-corporate-governance-key-documents-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/corporate-governance/key-documents.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-corporate-governance-key-documents-js" */),
  "component---src-pages-about-helios-underwriting-directors-and-management-index-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/directors-and-management/index.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-directors-and-management-index-js" */),
  "component---src-pages-about-helios-underwriting-overview-index-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/overview/index.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-overview-index-js" */),
  "component---src-pages-about-helios-underwriting-portfolio-and-capacity-index-js": () => import("/opt/build/repo/src/pages/about-helios-underwriting/portfolio-and-capacity/index.js" /* webpackChunkName: "component---src-pages-about-helios-underwriting-portfolio-and-capacity-index-js" */),
  "component---src-pages-contact-google-map-js": () => import("/opt/build/repo/src/pages/contact/google-map.js" /* webpackChunkName: "component---src-pages-contact-google-map-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("/opt/build/repo/src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-announcements-index-js": () => import("/opt/build/repo/src/pages/investors/announcements/index.js" /* webpackChunkName: "component---src-pages-investors-announcements-index-js" */),
  "component---src-pages-investors-documents-and-papers-index-js": () => import("/opt/build/repo/src/pages/investors/documents-and-papers/index.js" /* webpackChunkName: "component---src-pages-investors-documents-and-papers-index-js" */),
  "component---src-pages-investors-financial-summary-index-js": () => import("/opt/build/repo/src/pages/investors/financial-summary/index.js" /* webpackChunkName: "component---src-pages-investors-financial-summary-index-js" */),
  "component---src-pages-investors-placing-and-open-offer-index-js": () => import("/opt/build/repo/src/pages/investors/placing-and-open-offer/index.js" /* webpackChunkName: "component---src-pages-investors-placing-and-open-offer-index-js" */),
  "component---src-pages-investors-placing-and-open-offer-open-offer-js": () => import("/opt/build/repo/src/pages/investors/placing-and-open-offer/open-offer.js" /* webpackChunkName: "component---src-pages-investors-placing-and-open-offer-open-offer-js" */),
  "component---src-pages-investors-reports-and-accounts-index-js": () => import("/opt/build/repo/src/pages/investors/reports-and-accounts/index.js" /* webpackChunkName: "component---src-pages-investors-reports-and-accounts-index-js" */),
  "component---src-pages-investors-reports-and-accounts-reports-js": () => import("/opt/build/repo/src/pages/investors/reports-and-accounts/reports.js" /* webpackChunkName: "component---src-pages-investors-reports-and-accounts-reports-js" */),
  "component---src-pages-investors-shareholders-index-js": () => import("/opt/build/repo/src/pages/investors/shareholders/index.js" /* webpackChunkName: "component---src-pages-investors-shareholders-index-js" */),
  "component---src-pages-investors-shareholders-related-documents-js": () => import("/opt/build/repo/src/pages/investors/shareholders/related-documents.js" /* webpackChunkName: "component---src-pages-investors-shareholders-related-documents-js" */),
  "component---src-pages-investors-the-investment-case-index-js": () => import("/opt/build/repo/src/pages/investors/the-investment-case/index.js" /* webpackChunkName: "component---src-pages-investors-the-investment-case-index-js" */),
  "component---src-pages-leading-voices-martin-reith-index-js": () => import("/opt/build/repo/src/pages/leading-voices-martin-reith/index.js" /* webpackChunkName: "component---src-pages-leading-voices-martin-reith-index-js" */),
  "component---src-pages-legal-notices-index-js": () => import("/opt/build/repo/src/pages/legal-notices/index.js" /* webpackChunkName: "component---src-pages-legal-notices-index-js" */),
  "component---src-pages-modern-slavery-statement-index-js": () => import("/opt/build/repo/src/pages/modern-slavery-statement/index.js" /* webpackChunkName: "component---src-pages-modern-slavery-statement-index-js" */),
  "component---src-pages-press-index-js": () => import("/opt/build/repo/src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("/opt/build/repo/src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-sell-your-lloyds-vehicle-confirmation-index-js": () => import("/opt/build/repo/src/pages/sell-your-lloyds-vehicle/confirmation/index.js" /* webpackChunkName: "component---src-pages-sell-your-lloyds-vehicle-confirmation-index-js" */),
  "component---src-pages-sell-your-lloyds-vehicle-index-js": () => import("/opt/build/repo/src/pages/sell-your-lloyds-vehicle/index.js" /* webpackChunkName: "component---src-pages-sell-your-lloyds-vehicle-index-js" */)
}

